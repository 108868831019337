import React, { lazy, StrictMode, useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router';
import { ApolloProvider } from '@apollo/client';
import 'moment/locale/ru';
import { useClient } from 'src/apollo';
import './i18n';
import ContextProvider from './context';
import ScrollUpButton from 'components/ScrollUpButton';
// import "./assets/scss/themes/vean.scss";
import './assets/scss/themes/mint.scss';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Authentication from './views/Auth/index.jsx';
import UpdateCrmNotif from './components/UpdateCrmNotif';
import 'moment-duration-format';

import moment from 'moment-timezone';

const domainLocaleMap = {
  ca: 'Canada/Pacific',
  uk: 'Europe/London',
  de: 'Europe/Berlin',
  lv: 'Europe/Riga',
  pl: 'Europe/Warsaw',
  es: 'Europe/Madrid',
  com: 'Europe/Kyiv',
  bg: 'Europe/Sofia',
  cz: 'Europe/Prague',
  ee: 'Europe/Tallinn',
  hu: 'Europe/Budapest',
  lt: 'Europe/Vilnius',
  nl: 'Europe/Amsterdam',
  sk: 'Europe/Bratislava',
};

moment.tz.setDefault(domainLocaleMap[window.location.hostname.split('.')[0]]);

const now = new Date();
const night = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() + 1, // the next day, ...
  0,
  0,
  0, // ...at 00:00:00 hours
);
const msTillMidnight = night.getTime() - now.getTime();
setTimeout(document.location.refresh, msTillMidnight);

String.prototype.capitalize = function () {
  // eslint-disable-line no-extend-native
  return this.charAt(0).toUpperCase() + this.slice(1);
};

if (localStorage.getItem('newTheme')) {
  document.body.classList.add('black-content');
}

const Candidates = lazy(() => import('./views/Candidates/candidates'));
const Layout = lazy(() => import('./layouts/index.jsx'));

const App = () => {
  const client = useClient();

  if (!client) return;
  return (
    <ApolloProvider client={client}>
      <ContextProvider>
        <UpdateCrmNotif />
        <BrowserRouter>
          {/* <AnalyticsWrapper enabled={true}> */}
          <Routes>
            <Route path='/Candidate/*' element={<Candidates />} />
            <Route path='/auth/*' element={<Authentication />} />
            <Route path='/crm/*' element={<Layout />} />
            <Route path='/shop/*' element={<Layout />} />
            <Route path='*' element={<Navigate to='/crm/' replace />} />
          </Routes>
          {/* </AnalyticsWrapper> */}
        </BrowserRouter>
        <ScrollUpButton />
      </ContextProvider>
    </ApolloProvider>
  );
};

const root = createRoot(document.getElementById('vean'));
root.render(
  // <StrictMode>
  <I18nextProvider i18n={i18n}>
    <App />,
  </I18nextProvider>,

  /* </StrictMode> */
);
