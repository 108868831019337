import React, { useCallback, useMemo } from 'react';
import { FormText, FormFeedback } from 'reactstrap';
import {
  defaultStyles,
  FileIcon,
} from './FileIcon';
import { useDropzone } from 'react-dropzone';
import { toBase64 } from 'helpers';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faAndroid } from '@fortawesome/free-brands-svg-icons';;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';
import CutImageModal from './CutImageModal';

const FileField = React.memo((props) => {
  const {
    name,
    multiple,
    error,
    setFieldValue,
    label,
    helpText,
    value,
    touched = true,
    accept,
    onChange,
  } = props;
  const onDrop = useCallback(
    (acceptedFiles) => {
      Promise.all(
        acceptedFiles
          .filter((el) => !value.filter((file) => file.name === el.name).length)
          .map(async (file) => ({
            name: file.name,
            type: file.type,
            extension: file.name.split('.')[file.name.split('.').length - 1],
            base64: await toBase64(file).then((res) => res),
          })),
      ).then((res) => {
        const updatedFiles = [...value, ...res];
        setFieldValue(name, [...value, ...res]);
        if (onChange) {
          onChange(updatedFiles);
        }
      });
    },
    [value, setFieldValue, name],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });
  const { onClick, ...otherProps } = useMemo(() => getRootProps(), [getRootProps]);
  const { t } = useTranslation();
  return (
    <div className='form-group'>
      <h5 className='text-uppercase text-center'>{label}</h5>
      {value?.map((file) => (
        <div key={file.name} style={{ margin: '5px 2px' }}>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <div style={{ width: '2rem', height: '2rem', marginRight: '15px' }}>
                <FileIcon {...defaultStyles[file.extension]} />
              </div>
              <span>{file.name}</span>
            </div>
            <div>
              {file.type?.indexOf('image') != -1 && (
                <CutImageModal
                  file={file}
                  setFieldValue={setFieldValue}
                  name={name}
                  value={value}
                />
              )}
              <FontAwesomeIcon
                icon={faSearch}
                className='cursor-edit'
                size='lg'
                style={{ marginRight: '10px' }}
                onClick={() =>
                  Swal.fire({
                    imageUrl: file.base64,
                    imageAlt: '...',
                  })
                }
              />
              <FontAwesomeIcon
                icon={faTrash}
                className='cursor-delete'
                size='lg'
                onClick={() =>
                  setFieldValue(
                    name,
                    value.filter((el) => el.name !== file.name),
                  )
                }
              />
            </div>
          </div>
        </div>
      ))}
      <input {...getInputProps()} />
      {(multiple || !value?.length) && (
        <div
          {...otherProps}
          className='d-flex justify-content-center align-items-center'
          style={{
            height: '100px',
            padding: '15px',
            borderRadius: '5px',
            border: 'dashed',
          }}
        >
          {isDragActive ? (
            <p>{t('global.add_download')}</p>
          ) : (
            <div className='d-flex flex-column align-items-center text-center text-uppercase'>
              <p style={{ margin: 0 }}>{t('global.drag_file')}</p>
              <p style={{ margin: 0 }}>
                OR{' '}
                <a href='#a' onClick={onClick}>
                  {t('global.choose')}
                </a>
              </p>
            </div>
          )}
        </div>
      )}
      {helpText && <FormText>{helpText}</FormText>}
      {!Array.isArray(error) && error && touched && (
        <FormFeedback className='d-block'>{error}</FormFeedback>
      )}
    </div>
  );
});

export default FileField;
