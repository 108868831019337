import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { useCallback, useContext, useState } from 'react';
import { getUploadsURL, handleAxiosErrorSwal, selectStyles, toBase64 } from 'helpers';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';

export const Unboxing = ({ item, data, isOpenUnboxing, setIsOpenUnboxing, refetch }) => {
  const [expirationDate, setExpirationDate] = useState(null);
  const [lotValue, setLotValue] = useState('');
  const { t } = useTranslation();

  const { user, api } = useContext(UserContext);

  const unwrapPackage = useCallback(() => {
    const currentDate = new Date();
    const nextYearDate = new Date();
    nextYearDate.setFullYear(currentDate.getFullYear() + 1);

    Swal.fire({
      title: t('Открыть?'),
      showCancelButton: true,
      cancelButtonText: t('buttons.cancel'),
      confirmButtonText: t('buttons.save'),
      showLoaderOnConfirm: true,
      preConfirm() {
        return api
          .post(`/parlorstore-expiration-date/`, {
            is_opened: true,
            id: expirationDate.value,
            parlor_store: item.id,
            end_date: nextYearDate.toISOString().split('T')[0],
            amount: 1,
            lot: lotValue,
          })
          .then(() => {
            refetch();
            Swal.fire(t('swanl.save'), '', 'success');
          })
          .catch(handleAxiosErrorSwal);
      },
    });
  }, [api, t, expirationDate, lotValue]);

  return (
    <Modal
      size={'lg'}
      isOpen={isOpenUnboxing}
      toggle={() => {
        setIsOpenUnboxing('');
      }}
    >
      <ModalHeader
        toggle={() => {
          setIsOpenUnboxing('');
        }}
      >
        <h3>
          {item.item.name} {item.item.attribute?.name}
        </h3>
      </ModalHeader>
      <ModalBody className='d-flex flex-column'>
        <Row>
          <Col xs={8}>
            {data?.expirationdates
              .filter((el) => el.is_opened)
              .map((el) => (
                <Col key={el.id}>
                  <Row
                    className='d-flex justify-content-center align-items-center'
                    style={{ gap: '10px' }}
                  >
                    <p style={{ fontSize: '16px', margin: 0 }}>
                      {moment(el.end_date).format('DD.MM.YYYY HH:mm')} -{' '}
                      <span style={{ fontWeight: 'bold' }}>{el.transactions[0].quantity}</span>
                    </p>
                  </Row>
                </Col>
              ))}
          </Col>
          <Col xs={12} className='d-flex ' style={{ gap: '10px', flexDirection: 'column' }}>
            <Select
              placeholder={'Выбрать партию'}
              options={data?.expirationdates
                .filter((el) => !el?.is_opened && el.transactions[0].quantity >= 1)
                .map((el) => ({
                  ...el,
                  label: `${moment(el.end_date).format('DD-MM-YYYY')} - ${
                    el.transactions[0].quantity
                  }`,
                  value: el.id,
                }))}
              styles={selectStyles(false)}
              onChange={(e) => {
                setExpirationDate(e);
              }}
            />
            <Input
              placeholder='Введите LOT'
              value={lotValue}
              onChange={(e) => setLotValue(e.target.value)}
            />
            <Button
              onClick={() => {
                unwrapPackage();
              }}
            >
              Открыть упаковку
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
